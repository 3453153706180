import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

function PlayerResultsTable({ selectedPlayer, setSelectedPlayer, setSearchTerm }) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const player = params.get('player');
    const [results, setResults] = useState([]);
    const [winsLosses, setH2hScore] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedPlayer) {
            fetch(`https://betapi.tnx-solutions.ch/api/v1/player/results/${selectedPlayer?.person?.id}`)
                .then((response) => response.json())
                .then((data) => {
                    const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
                    setResults(sortedData);
                    const wins = data.filter(row => row.playerWinnerCode === "S" || row.playerWinnerCode === "W").length;
                    const losses = data.filter(row => row.playerWinnerCode === "N" || row.playerWinnerCode === "Z").length;
                    setH2hScore(`${wins}-${losses}`);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [selectedPlayer]);

    const getResult = (row) => {
        const sets = [];
        for (let i = 1; i <= 5; i++) {
            const playerGames = row[`playerSet${i}WonGames`];
            const adversaryGames = row[`adversarySet${i}WonGames`];
            if (playerGames !== -1 && adversaryGames !== -1) {
                sets.push(`${playerGames}-${adversaryGames}`);
            }
        }
        return sets.join(', ');
    };

    const handleRowClick = (adversaryPersonId) => {
        fetch(`https://betapi.tnx-solutions.ch/api/v1/player/${adversaryPersonId}`)
            .then((response) => response.json())
            .then((data) => {
                setSelectedPlayer(data[0]);
                navigate(`?player=${data[0]?.person?.id}`);
                setSearchTerm('');
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    return (
        <>
            <Typography variant="h8" component="div" gutterBottom align="center" sx={{mb: 2}}>
                Siege : Niederlagen {winsLosses}
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: {xs: '30px', sm: '600px'}, maxWidth: {xs: '300px', sm: '600px'}}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Datum</TableCell>
                            <TableCell>Gegner</TableCell>
                            <TableCell>Klassierung</TableCell>
                            <TableCell>Resultat</TableCell>
                            <TableCell>Code</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.map((result) => (
                            <TableRow key={result.id}>
                                <TableCell>{new Date(result.date).toLocaleDateString()}</TableCell>
                                <TableCell
                                    onClick={() => handleRowClick(result.adversaryPersonId)}
                                    sx={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        '&:hover': {
                                            backgroundColor: '#e0e0e0',
                                        },
                                    }}
                                >
                                    {`${result.adversaryFirstname} ${result.adversaryLastname}`}
                                </TableCell>
                                <TableCell>{result.adversary?.classification}</TableCell>
                                <TableCell>{getResult(result)}</TableCell>
                                <TableCell>{result.playerWinnerCode}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default PlayerResultsTable;

import React, {useCallback, useState} from 'react';
import {TextField, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Autocomplete, LinearProgress, Typography, Grid} from '@mui/material';
import _ from 'lodash';

function H2H() {
    const [searchTerm1, setSearchTerm1] = useState('');
    const [searchTerm2, setSearchTerm2] = useState('');
    const [selectedPlayer1, setSelectedPlayer1] = useState(null);
    const [selectedPlayer2, setSelectedPlayer2] = useState(null);
    const [data, setData] = useState([]);
    const [suggestions1, setSuggestions1] = useState([]);
    const [suggestions2, setSuggestions2] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [h2hScore, setH2hScore] = useState('');

    const handleSearch = () => {
        if (selectedPlayer1 && selectedPlayer2) {
            setLoading(true);
            const player1Id = selectedPlayer1.person.id;
            const player2Id = selectedPlayer2.person.id;
            fetch(`https://betapi.tnx-solutions.ch/api/v1/player/h2h/${player1Id}/${player2Id}`)
                .then((response) => response.json())
                .then((data) => {
                    const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
                    setData(sortedData);
                    const player1Wins = data.filter(row => row.playerWinnerCode == "S").length;
                    const player2Wins = data.length - player1Wins;
                    setH2hScore(`${player1Wins}-${player2Wins}`);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        } else {
            alert('Please select two players.');
        }
    };

    const handleSuggestionsFetch = (searchTerm, setSuggestions, setOpen) => {
        if (searchTerm.length >= 3) {
            setLoading(true);
            fetch(`https://betapi.tnx-solutions.ch/api/v1/player/search/${searchTerm}`)
                .then((response) => response.json())
                .then((data) => {
                    setSuggestions(data);
                    setOpen(true);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
    };

    const debouncedHandleSuggestionsFetch1 = useCallback(
        _.debounce((searchTerm1) => handleSuggestionsFetch(searchTerm1, setSuggestions1, setOpen1), 500),
        []
    );

    const debouncedHandleSuggestionsFetch2 = useCallback(
        _.debounce((searchTerm2) => handleSuggestionsFetch(searchTerm2, setSuggestions2, setOpen2), 500),
        []
    );

    const getResult = (row) => {
        const sets = [];
        for (let i = 1; i <= 5; i++) {
            const playerGames = row[`playerSet${i}WonGames`];
            const adversaryGames = row[`adversarySet${i}WonGames`];
            if (playerGames !== -1 && adversaryGames !== -1) {
                sets.push(`${playerGames}-${adversaryGames}`);
            }
        }
        return sets.join(', ');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                gap: 2,
                px: 3,
            }}
        >
            <Typography variant="h4" component="div" gutterBottom align="center" sx={{fontWeight: 'bold', mb: 3}}>
                H2H
            </Typography>
            <Grid container spacing={2} alignItems="center" justifyContent="center" style={{maxWidth: '90%'}}>
                <Grid item xs={12} sm={5}>
                    <Autocomplete
                        freeSolo
                        open={open1}
                        onOpen={() => setOpen1(true)}
                        onClose={() => setOpen1(false)}
                        options={suggestions1}
                        onChange={(event, newValue) => setSelectedPlayer1(newValue)}
                        getOptionLabel={(option) => {
                            const matchesFirstnameLastname = searchTerm1.toLowerCase().includes(`${option.person.firstname.toLowerCase()} ${option.person.lastname.toLowerCase()}`);
                            return matchesFirstnameLastname
                                ? `${option.person.firstname} ${option.person.lastname} (${option.classification}/${option.ranking})`
                                : `${option.person.lastname} ${option.person.firstname} (${option.classification}/${option.ranking})`;
                        }}
                        renderOption={(props, option, {inputValue}) => {
                            const matchesFirstnameLastname = inputValue.toLowerCase().includes(`${option.person.firstname.toLowerCase()} ${option.person.lastname.toLowerCase()}`);
                            const label = matchesFirstnameLastname
                                ? `${option.person.firstname} ${option.person.lastname} (${option.classification}/${option.ranking})`
                                : `${option.person.lastname} ${option.person.firstname} (${option.classification}/${option.ranking})`;
                            return <li {...props}>{label}</li>;
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Spieler 1"
                                variant="outlined"
                                fullWidth
                                value={searchTerm1}
                                onChange={(e) => {
                                    setSearchTerm1(e.target.value);
                                    if (!e.target.value) {
                                        setData([]);
                                    }
                                    debouncedHandleSuggestionsFetch1(e.target.value, setSuggestions1, setOpen1);
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Autocomplete
                        freeSolo
                        open={open2}
                        onOpen={() => setOpen2(true)}
                        onClose={() => setOpen2(false)}
                        options={suggestions2}
                        onChange={(event, newValue) => setSelectedPlayer2(newValue)}
                        getOptionLabel={(option) => {
                            const matchesFirstnameLastname = searchTerm2.toLowerCase().includes(`${option.person.firstname.toLowerCase()} ${option.person.lastname.toLowerCase()}`);
                            return matchesFirstnameLastname
                                ? `${option.person.firstname} ${option.person.lastname} (${option.classification}/${option.ranking})`
                                : `${option.person.lastname} ${option.person.firstname} (${option.classification}/${option.ranking})`;
                        }}
                        renderOption={(props, option, {inputValue}) => {
                            const matchesFirstnameLastname = inputValue.toLowerCase().includes(`${option.person.firstname.toLowerCase()} ${option.person.lastname.toLowerCase()}`);
                            const label = matchesFirstnameLastname
                                ? `${option.person.firstname} ${option.person.lastname} (${option.classification}/${option.ranking})`
                                : `${option.person.lastname} ${option.person.firstname} (${option.classification}/${option.ranking})`;
                            return <li {...props}>{label}</li>;
                        }} renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Spieler 2"
                            variant="outlined"
                            fullWidth
                            value={searchTerm2}
                            onChange={(e) => {
                                setSearchTerm2(e.target.value);
                                if (!e.target.value) {
                                    setData([]);
                                }
                                debouncedHandleSuggestionsFetch2(e.target.value, setSuggestions2, setOpen2);
                            }}
                        />
                    )}
                    />
                </Grid>
                {loading && <LinearProgress sx={{width: '90%', mt: 1}}/>}
                <Grid item xs={12} sm={2}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleSearch}>
                        H2H
                    </Button>
                </Grid>
            </Grid>
            {/* Display H2H score */}
            <Typography variant="h6" component="div" gutterBottom align="center" sx={{fontWeight: 'bold', mt: 2}}>
                {h2hScore}
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: {xs: '100%', sm: '650px'}}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Spieler 1</TableCell>
                            <TableCell>Spieler 2</TableCell>
                            <TableCell>Datum</TableCell>
                            <TableCell>Resultat</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{`${selectedPlayer1?.person?.firstname ?? 'Kein Spieler ausgewählt'} ${selectedPlayer1?.person?.lastname ?? ''}`}</TableCell>
                                <TableCell>{`${row.adversaryFirstname} ${row.adversaryLastname}`}</TableCell>
                                <TableCell>{new Date(row.date).toLocaleDateString()}</TableCell>
                                <TableCell>{getResult(row)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default H2H;

import React, {useCallback, useEffect, useState} from 'react';
import {TextField, Box, Typography, Autocomplete, LinearProgress, Grid} from '@mui/material';
import _ from 'lodash';
import PlayerResultsTable from "./PlayerResultsTable";
import PlayerDetails from './PlayerDetails';
import { useNavigate, useLocation } from 'react-router-dom';
import FavoritePlayers from "./FavoritePlayers";

function Home() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [key, setKey] = useState(0); // Add a key state

    useEffect(() => {
        if (selectedPlayer) {
            navigate(`?player=${selectedPlayer?.person?.id}`, {replace: true});
        } else {
            navigate(location.pathname, {replace: true});
        }
    }, [selectedPlayer]);

    const handleSuggestionsFetch = (searchTerm, setSuggestions, setOpen) => {
        if (searchTerm.length >= 3) {
            setLoading(true);
            fetch(`https://betapi.tnx-solutions.ch/api/v1/player/search/${searchTerm}`)
                .then((response) => response.json())
                .then((data) => {
                    setSuggestions([]);
                    setSuggestions(data);
                    setOpen(true);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
    };

    const debouncedHandleSuggestionsFetch = useCallback(
        _.debounce((searchTerm) => handleSuggestionsFetch(searchTerm, setSuggestions, setOpen), 500),
        []
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '100vh',
                width: '100%',
                gap: 2,
                pt: '20vh',
                margin: 'auto',
                // transform: selectedPlayer ? 'translateY(-20vh)' : 'translateY(0)',
                //transition: 'transform 0.5s',
            }}
        >
            <Typography variant="h4" component="div" gutterBottom align="center" sx={{fontWeight: 'bold'}}>
                Spieler suchen
            </Typography>
            <Autocomplete
                key={key}
                freeSolo
                open={open}
                sx={{minWidth: {xs: '300px', sm: '400px'}, maxWidth: {xs: '300px', sm: '400px'}}}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                options={suggestions}
                onChange={(event, newValue) => {
                    setSelectedPlayer(newValue);
                    setKey(prev => prev + 1); // Increment key to force remount
                }}
                getOptionLabel={(option) => {
                    const matchesFirstnameLastname = searchTerm.toLowerCase().includes(`${option.person.firstname.toLowerCase()} ${option.person.lastname.toLowerCase()}`);
                    return matchesFirstnameLastname
                        ? `${option.person.firstname} ${option.person.lastname} (${option.classification}/${option.ranking})`
                        : `${option.person.lastname} ${option.person.firstname} (${option.classification}/${option.ranking})`;
                }}
                renderOption={(props, option, {inputValue}) => {
                    const matchesFirstnameLastname = inputValue.toLowerCase().includes(`${option.person.firstname.toLowerCase()} ${option.person.lastname.toLowerCase()}`);
                    const label = matchesFirstnameLastname
                        ? `${option.person.firstname} ${option.person.lastname} (${option.classification}/${option.ranking})`
                        : `${option.person.lastname} ${option.person.firstname} (${option.classification}/${option.ranking})`;
                    return <li {...props}>{label}</li>;
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="Suchen..."
                    variant="outlined"
                    sx={{width: {xs: '100%', sm: '400px'}, borderRadius: '10px'}}
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setSuggestions([]);
                        debouncedHandleSuggestionsFetch(e.target.value, setSuggestions, setOpen);
                    }}
                />
            )}
            />
            {loading && <LinearProgress sx={{width: '100%', mb: 2}}/>}
            {/* Display selected player details here */}
            {selectedPlayer ? (
                <>
                    <PlayerDetails selectedPlayer={selectedPlayer} />
                    <Box
                        sx={{
                            p: 2,
                            mt: 2,
                            borderRadius: '10px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            backgroundColor: 'background.paper',
                            width: {xs: '80%', sm: '600px'},
                            border: '1px solid #e0e0e0'
                        }}>
                        <PlayerResultsTable key={selectedPlayer?.person?.id} selectedPlayer={selectedPlayer} setSelectedPlayer={setSelectedPlayer} setSearchTerm={setSearchTerm}/>
                    </Box>
                </>
            ) : (
                <FavoritePlayers setSelectedPlayer={setSelectedPlayer}/>
                )}
        </Box>
    );
}

export default Home;

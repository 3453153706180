import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

function FavoritePlayers({setSelectedPlayer}) {
    const [favorites, setFavorites] = useState([]);

    useEffect(() => {
        const playerIds = JSON.parse(localStorage.getItem('favoritePlayerIds')) || [];
        if (playerIds.length === 0) {
            // Early return if no favorites are stored
            return;
        }

        const fetchPlayerDetails = async (id) => {
            try {
                const response = await fetch(`https://betapi.tnx-solutions.ch/api/v1/player/${id}`);
                const data = await response.json();
                return data[0];
            } catch (error) {
                console.error('Error fetching player details:', error);
                return null;  // Return null in case of an error, which should be handled later.
            }
        };

        const fetchFavorites = async () => {
            const players = await Promise.all(playerIds.map(id => fetchPlayerDetails(id)));
            setFavorites(players.filter(player => player !== null)); // Filter out any nulls from fetch errors.
        };

        fetchFavorites();
    }, []);

    if (favorites.length === 0) {
        return (
            <Typography variant="h6" sx={{ mt: 2, textAlign: 'center' }}>
                Keine Favoriten vorhanden.
            </Typography>
        );
    }

    const setPlayer = (player) => {
        setSelectedPlayer(player);
    }

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2 }}>
            {favorites.map((player, index) => (
                <Card  key={index} sx={{ minWidth: 275, maxWidth: 300, cursor: 'pointer' }} onClick={() => setPlayer(player)}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {player?.person?.firstname} {player?.person?.lastname}
                        </Typography>
                        <Typography variant="body2">
                            Classification: {player?.classification}
                        </Typography>
                        <Typography variant="body2">
                            Live Ranking: {player?.liveRanking?.newRank}
                        </Typography>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
}

export default FavoritePlayers;
